<template>
    <div class="content-wrapper overflow-visible">
        <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.query.regId" />
        
        <div class="content">
            <validation-observer ref="VFormDokter">
                <b-form @submit.prevent="doSubmit()">
                <div class="card">
                    <div class="card-header bg-white" style="border-bottom: 1px solid rgba(0,0,0,.125);">
                        <h6 class="card-title font-weight-semibold">Kajian Awal Medis BBL</h6>
                    </div>
                    <div class="card-body"> 
                        <div class="wrap_line">
                            <h3> <span>Identitas</span></h3>
                            <div class="mb-3">
                            <table class="table table-bordered">
                                <tr>
                                <td width="16%">
                                <div class="result_tab">
                                    <h4>Nama Bayi</h4>
                                    <p>{{row.ap_fullname||"-"}}</p>
                                </div>
                                </td>
                                <td width="16%">
                                <div class="result_tab">
                                    <h4>Tanggal Lahir</h4>
                                    <p>{{row.ap_dob | moment("DD MMMM YYYY")}}</p>
                                </div>
                                </td>
                                <td width="16%">
                                <div class="result_tab">
                                    <h4>Jenis Kelamin</h4>
                                    <p>{{getConfigDynamic(Config.mr.StatusKelamin,row.ap_gender)||"-"}}</p>
                                </div>
                                </td>
                                <td width="16%">
                                <div class="result_tab">
                                    <h4>Nama Ayah</h4>
                                    <p>{{row.arpdo_ayah_nama||"-"}}</p>
                                </div>
                                </td>
                                <td width="16%">
                                    <div class="result_tab">
                                    <h4>Anak Ke- <strong class="text-danger">*</strong></h4>
                                    <b-form-input @input="onInput($event, 'anak-ke')" @blur="onBlur($event, 'anak-ke')" @focus="onFocus($event, 'anak-ke')" :disabled="disabled['anak-ke']" v-model="row.arankmb_anak_ke" :formatter="numberOnly" type="text"
                                    class="form-control" />
                                    <p class='text-muted' v-if="disabled['anak-ke']">User lain sedang mengisi...</p>
                                    <VValidate name="Anak Ke- " v-model="row.arankmb_anak_ke"
                                    :rules="toValidate(mrValidation.arankmb_anak_ke)" />
                                    </div>
                                </td>
                                <td width="16%">
                                    <div class="result_tab">
                                    <h4>Lahir dengan cara</h4>
                                    <v-select placeholder="--Pilih Item--" v-model="row.arankmb_lahir_dengan_cara"
                                        :options="Config.mr.mCaraLahir" label="text" :reduce="v=>v.value">
                                    </v-select>
                                    </div>
                                </td>
                                </tr>
                            </table>
                            <div class="row">
                                <div class="col-md-6">
                                    <table class="table table-sm table-bordered mt-3">
                                    <thead>
                                        <tr>
                                        <th class="bg-success" colspan="2">Biodata Ibu</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                        <td>Nama Ibu</td>
                                        <td>{{row.arpdo_ibu_nama||"-"}}</td>
                                        </tr>
                                        <tr>
                                        <td>Tanggal Lahir</td>
                                        <td>
                                            <span v-if="row.arpdo_ibu_dob">
                                            {{row.arpdo_ibu_dob | moment("DD MMMM YYYY")}}
                                            </span>
                                            <span v-else> - </span>
                                        </td>
                                        </tr>
                                        <tr>
                                        <td>Umur</td>
                                        <td>
                                            <span v-if="row.arpdo_ibu_dob">
                                            {{ row.arpdo_ibu_dob | moment("from", "now", true) }}
                                            </span>
                                            <span v-else> - </span>
                                        </td>
                                        
                                        </tr>
                                        <tr>
                                        <td>Suku/bangsa </td>
                                        <td>{{row.ibu_suku||"-"}}</td>
                                        </tr>
                                        <tr>
                                        <td>Agama </td>
                                        <td>{{row.ibu_agama||"-"}}
                                        <span v-if="row.ibu_agama == 'Lain-lain'">: {{row.arpdo_ibu_agama_text||"-"}}</span>
                                        </td>
                                        </tr>
                                        <tr>
                                        <td>Pendidikan </td>
                                        <td>{{row.ibu_pendidikan||"-"}}
                                        <span v-if="row.ibu_pendidikan == 'Lain-lain'">: {{row.arpdo_ibu_pendidikan_text||"-"}}</span>
                                        </td>
                                        
                                        </tr>
                                        <tr>
                                        <td>Pekerjaan</td>
                                        <td>{{row.ibu_pekerjaan||"-"}}
                                        <span v-if="row.ibu_pekerjaan == 'Lain-lain'">: {{row.arpdo_ibu_pekerjaan_text||"-"}}</span>
                                        </td>
                                        </tr>
                                        <tr>
                                        <td>Alamat </td>
                                        <td>{{row.arpdo_ibu_alamat_ktp||"-"}}</td>
                                        </tr>
                                        <tr>
                                        <td>Status Perkawinan </td>
                                        <td>{{getConfigDynamic(Config.mr.StatusPernikahan,row.arpdo_ibu_status_perkawinan)||"-"}}</td>
                                        </tr>
                                    </tbody>
                                    </table>
                                </div>
                                <div class="col-md-6">
                                    <table class="table table-sm table-bordered mt-3">
                                    <thead>
                                        <tr>
                                        <th class="bg-primary" colspan="2">Biodata Ayah</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                        <td>Nama Ayah</td>
                                        <td>{{row.arpdo_ayah_nama||"-"}}</td>
                                        </tr>
                                        <tr>
                                        <td>Tanggal Lahir</td>
                                        <td>
                                            <span v-if="row.arpdo_ayah_dob">
                                            {{row.arpdo_ayah_dob | moment("DD MMMM YYYY")}}
                                            </span>
                                            <span v-else> - </span>
                                        </td>
                                        </tr>
                                        <tr>
                                        <td>Umur</td>
                                        <td>
                                            <span v-if="row.arpdo_ayah_dob">
                                            {{ row.arpdo_ayah_dob | moment("from", "now", true) }}
                                            </span>
                                            <span v-else> - </span>
                                        </td>
                                        
                                        </tr>
                                        <tr>
                                        <td>Suku/bangsa </td>
                                        <td>{{row.ayah_suku||"-"}}</td>
                                        </tr>
                                        <tr>
                                        <td>Agama </td>
                                        <td>{{row.ayah_agama||"-"}}
                                        <span v-if="row.ayah_agama == 'Lain-lain'">: {{row.arpdo_ayah_agama_text||"-"}}</span>
                                        </td>
                                        </tr>
                                        <tr>
                                        <td>Pendidikan </td>
                                        <td>{{row.ayah_pendidikan||"-"}}
                                        <span v-if="row.ayah_pendidikan == 'Lain-lain'">: {{row.arpdo_ayah_pendidikan_text||"-"}}</span>
                                        </td>
                                        </tr>
                                        <tr>
                                        <td>Pekerjaan</td>
                                        <td>{{row.ayah_pekerjaan||"-"}}
                                        <span v-if="row.ayah_pekerjaan == 'Lain-lain'">: {{row.arpdo_ayah_pekerjaan_text||"-"}}</span>
                                        </td>
                                        </tr>
                                        <tr>
                                        <td>Alamat </td>
                                        <td>{{row.arpdo_ayah_alamat_ktp||"-"}}</td>
                                        </tr>
                                        <tr>
                                        <td>Status Perkawinan </td>
                                        <td>{{getConfigDynamic(Config.mr.StatusPernikahan,row.arpdo_ayah_status_perkawinan)||"-"}}</td>
                                        </tr>
                                    </tbody>
                                    </table>
                                </div>
                            </div>
                            </div>
                        </div>

                        <div class="wrap_line">
                            <h3><span>Riwayat Kesehatan</span></h3>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                    <label for="">Riwayat Ibu</label>
                                        <div>
                                        <div class="custom-control custom-radio custom-control-inline pl-0">
                                            <b-form-radio-group :options="Config.mr.mConfigRiwayat"
                                            v-model="row.arankmb_riwayat_ibu" />
                                            <div class="ml-2 col-md-4" v-if="row.arankmb_riwayat_ibu == 'Y'">
                                            <b-form-input :formatter="normalText" v-model="row.arankmb_riwayat_ibu_text"
                                                type="text" class="form-control" />
                                            <VValidate name="Riwayat Ibu" v-model="row.arankmb_riwayat_ibu_text"
                                                :rules="{required: 1, min:3, max:128}" />
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>

                            <!--<div class="col-md-3">
                                <div class="form-group">
                                <label for="">Riwayat Keluarga</label>
                                <div>
                                    <div class="custom-control custom-radio custom-control-inline"><input type="radio"
                                        name="RiwayatKeluarga1" id="RiwayatKeluarga1" class="custom-control-input"><label
                                        for="RiwayatKeluarga1" class="custom-control-label">Tidak Ada</label></div>
                                    <div class="custom-control custom-radio custom-control-inline"><input type="radio"
                                        name="RiwayatKeluarga2" id="RiwayatKeluarga2" class="custom-control-input"><label
                                        for="RiwayatKeluarga2" class="custom-control-label">Ada</label></div>
                                </div>
                                </div>
                            </div>-->
                            </div>
                        </div>

                        <div class="wrap_line">
                            <h3><span>Pemeriksaan Fisik</span></h3>
                            <div class="card mb-2">
                            <div class="card-header bg_head_panel">
                                <h6 class="card-title font-weight-semibold">Status Praesens</h6>
                            </div>
                            <div class="card-body p-3">
                                <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                    <label for="JenisKelamin">Jenis Kelamin <strong class="text-danger">*</strong> </label>
                                    <v-select @input="onInput($event, 'jenis-kelamin')" @close="onBlur($event, 'jenis-kelamin')" @open="onFocus($event, 'jenis-kelamin')" :disabled="disabled['jenis-kelamin']" placeholder="--Pilih Item--" v-model="row.arankmb_jenis_kelamin"
                                        :options="Config.mr.genderText" label="text" :reduce="v=>v.value">
                                    </v-select>
                                    <p class='text-muted' v-if="disabled['jenis-kelamin']">User lain sedang mengisi...</p>
                                    <VValidate name="Jenis Kelamin" v-model="row.arankmb_jenis_kelamin"
                                    :rules="toValidate(mrValidation.arankmb_jenis_kelamin)" />
                                    </div>
                                </div>
                                <div class="col-md-3 col-lg-2">
                                    <div class="form-group">
                                        <label for="bayiBeratBadan">Berat Badan <strong class="text-danger">*</strong></label>
                                        <div class="input-group">
                                            <b-form-input @input="onInput($event, 'berat-badan')" @blur="onBlur($event, 'berat-badan')" @focus="onFocus($event, 'berat-badan')" :disabled="disabled['berat-badan']" :formatter="number" v-model="row.arankmb_berat_badan" type="text" class="form-control" />
                                            <div class="input-group-append"><span class="input-group-text">gram</span></div>
                                        </div>
                                        <p class='text-muted' v-if="disabled['berat-badan']">User lain sedang mengisi...</p>
                                        <VValidate name="Berat Badan" v-model="row.arankmb_berat_badan"
                                        :rules="toValidate(mrValidation.arankmb_berat_badan)" />
                                    </div>
                                </div>

                                <div class="col-md-3 col-lg-2">
                                    <div class="form-group">
                                    <label for="bayiPanjang">Panjang Badan<strong class="text-danger">*</strong></label>
                                    <div class="input-group">
                                        <b-form-input @input="onInput($event, 'panjang-badan')" @blur="onBlur($event, 'panjang-badan')" @focus="onFocus($event, 'panjang-badan')" :disabled="disabled['panjang-badan']" :formatter="number" v-model="row.arankmb_panjang" type="text" class="form-control" />
                                        <div class="input-group-append"><span class="input-group-text">cm</span></div>
                                    </div>
                                    <p class='text-muted' v-if="disabled['panjang-badan']">User lain sedang mengisi...</p>
                                    <VValidate name="Panjang Badan" v-model="row.arankmb_panjang"
                                    :rules="toValidate(mrValidation.arankmb_panjang)" />
                                    </div>
                                </div>

                                <div class="col-md-3 col-lg-2">
                                    <div class="form-group">
                                    <label for="LingkarKepala">Lingkar Kepala <strong class="text-danger">*</strong></label>
                                    <div class="input-group">
                                        <b-form-input @input="onInput($event, 'lingkar-kepala')" @blur="onBlur($event, 'lingkar-kepala')" @focus="onFocus($event, 'lingkar-kepala')" :disabled="disabled['lingkar-kepala']" :formatter="number" v-model="row.arankmb_lingkar_kepala" type="text" class="form-control" />
                                        <div class="input-group-append"><span class="input-group-text">cm</span></div>
                                    </div>
                                    <p class='text-muted' v-if="disabled['lingkar-kepala']">User lain sedang mengisi...</p>
                                    <VValidate name="Lingkar Kepala" v-model="row.arankmb_lingkar_kepala"
                                    :rules="toValidate(mrValidation.arankmb_lingkar_kepala)" />
                                    </div>
                                </div>

                                <div class="col-md-3 col-lg-2">
                                    <div class="form-group">
                                    <label for="bayiPanjang">Lingkar Dada <strong class="text-danger">*</strong></label>
                                    <div class="input-group">
                                        <b-form-input @input="onInput($event, 'lingkar-dada')" @blur="onBlur($event, 'lingkar-dada')" @focus="onFocus($event, 'lingkar-dada')" :disabled="disabled['lingkar-dada']" :formatter="number" v-model="row.arankmb_lingkar_dada" type="text" class="form-control" />
                                        <div class="input-group-append"><span class="input-group-text">cm</span></div>
                                    </div>
                                    <p class='text-muted' v-if="disabled['lingkar-dada']">User lain sedang mengisi...</p>
                                    <VValidate name="Lingkar Dada" v-model="row.arankmb_lingkar_dada"
                                    :rules="toValidate(mrValidation.arankmb_lingkar_dada)" />
                                    </div>
                                </div>


                                <div class="col-md-3 col-lg-2">
                                    <div class="form-group">
                                    <label>Nadi</label>
                                    <div class="input-group">
                                        <b-form-input @input="onInput($event, 'nadi')" @blur="onBlur($event, 'nadi')" @focus="onFocus($event, 'nadi')" :disabled="disabled['nadi']" :formatter="number" v-model="row.arankmb_nadi" type="text" class="form-control" />
                                        <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                                    </div>
                                    <p class='text-muted' v-if="disabled['nadi']">User lain sedang mengisi...</p>
                                    <!--
                                    <VValidate name="Nadi" v-model="row.arankmb_nadi"
                                    :rules="toValidate(mrValidation.arankmb_nadi)" />
                                    -->
                                    </div>
                                </div>

                                <div class="col-md-3 col-lg-2">
                                    <div class="form-group">
                                    <label>RR</label>
                                    <div class="input-group">
                                        <b-form-input @input="onInput($event, 'rr')" @blur="onBlur($event, 'rr')" @focus="onFocus($event, 'rr')" :disabled="disabled['rr']" :formatter="number" v-model="row.arankmb_rr" type="text" class="form-control" />
                                        <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                                    </div>
                                    <p class='text-muted' v-if="disabled['rr']">User lain sedang mengisi...</p>
                                    <!--
                                    <VValidate name="RR" v-model="row.arankmb_rr"
                                    :rules="toValidate(mrValidation.arankmb_rr)" />
                                    -->
                                    </div>
                                </div>

                                <div class="col-md-3 col-lg-2">
                                    <div class="form-group">
                                        <label>Suhu Badan</label>
                                        <div class="input-group">
                                            <b-form-input @input="onInput($event, 'suhu-badan')" @blur="onBlur($event, 'suhu-badan')" @focus="onFocus($event, 'suhu-badan')" :disabled="disabled['suhu-badan']" :formatter="number"
                                            v-model="row.arankmb_suhu" type="text"
                                            class="form-control" />
                                            <div class="input-group-append"><span class="input-group-text">Celcius</span></div>
                                        </div>
                                        <p class='text-muted' v-if="disabled['suhu-badan']">Someone is editing...</p>
                                        <!--
                                        <VValidate name="Suhu Badan" v-model="row.arankmb_suhu"
                                        :rules="toValidate(mrValidation.arankmb_suhu)" />
                                        -->
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div class="card mb-2">
                            <div class="card-header bg_head_panel">
                                <h6 class="card-title font-weight-semibold">Keadaan Umum</h6>
                            </div>
                            <div class="card-body p-3">
                                <div class="row">
                                <div class="col-md-4 col-lg-3">
                                    <div class="form-group">
                                    <div class="mb-2">
                                        <label for="Kesan">Kesan </label>
                                        <v-select placeholder="--Pilih Item--" v-model="row.arankmb_kesan"
                                            :options="Config.mr.mRanapBBLMedisKesan" label="text" :reduce="v=>v.value">
                                        </v-select>
                                    </div>
                                    </div>
                                </div>

                                <div class="col-md-4 col-lg-3">
                                    <div class="form-group">
                                    <div class="mb-2">
                                        <label for="Warna">Warna </label>
                                        <v-select placeholder="--Pilih Item--" v-model="row.arankmb_warna"
                                            :options="Config.mr.mRanapBBLMedisWarna" label="text" :reduce="v=>v.value">
                                        </v-select>
                                    </div>
                                    </div>
                                </div>

                                <div class="col-md-4 col-lg-3">
                                    <div class="form-group">
                                    <div class="mb-2">
                                        <label for="Pernafasan">Pernafasan </label>
                                        <v-select placeholder="--Pilih Item--" v-model="row.arankmb_pernafasan"
                                            :options="Config.mr.mRanapBBLMedisNafas" label="text" :reduce="v=>v.value">
                                        </v-select>
                                    </div>
                                    </div>
                                </div>

                                <div class="col-md-4 col-lg-3">
                                    <div class="form-group">
                                    <div class="mb-2">
                                        <label for="Tonus">Tonus </label>
                                        <v-select placeholder="--Pilih Item--" v-model="row.arankmb_tonus"
                                            :options="Config.mr.mRanapBBLMedisTonus" label="text" :reduce="v=>v.value">
                                        </v-select>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>

                        <div class="wrap_line">
                            <h3><span>Pemeriksaan Khusus</span></h3>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card mb-2">
                                    <div class="card-header bg_head_panel">
                                        <h6 class="card-title font-weight-semibold">Kepala <span class="text-danger">*</span></h6>
                                    </div>
                                    <div class="card-body p-3">
                                        <div class="row g-0">
                                        <div class="col-md-6">
                                            
                                            <div class="gen-part">
                                            <div class="d-flex align-items-center justify-content-between gen-part-head"
                                                @click="bentukOpen = !bentukOpen" :class="bentukOpen ? null : 'collapsed'"
                                                :aria-expanded="bentukOpen ? 'true' : 'false'" aria-controls="bentukOpen">
                                                <div class="font-weight-semibold">Bentuk</div>
                                                <i class="icon-arrow-down22"></i>
                                            </div>

                                            <b-collapse :id="'bentukOpen'" v-model="bentukOpen">
                                                <div class="gen-part-body mt-2">
                                                <div>
                                                    <b-form-radio-group :options="Config.mr.mRanapBBLBentuk"
                                                    v-model="row.arankmb_kepala_bentuk" />
                                                </div>
                                                <div class="gen-input mt-3">
                                                    <div class="form-row">
                                                    <div class="form-group col-md-12">
                                                        <textarea v-model="row.arankmb_kepala_bentuk_text" class="form-control"
                                                        placeholder="Tuliskan" rows="2"></textarea>
                                                    </div>
                                                    </div>
                                                </div>
                                                            
                                                <VValidate name="Tipe Bentuk" v-model="row.arankmb_kepala_bentuk"
                                                :rules="{required: 1}" />
                                                
                                                <!--
                                                <VValidate name="Bentuk" v-model="row.arankmb_kepala_bentuk_text"
                                                :rules="{required: 1}" />
                                                -->
                                                
                                                </div>
                                            </b-collapse>
                                            </div>


                                            <div class="gen-part">
                                            <div class="d-flex align-items-center justify-content-between gen-part-head"
                                                @click="suturaeOpen = !suturaeOpen" :class="suturaeOpen ? null : 'collapsed'"
                                                :aria-expanded="suturaeOpen ? 'true' : 'false'" aria-controls="suturaeOpen">
                                                <div class="font-weight-semibold">Suturae</div>
                                                <i class="icon-arrow-down22"></i>
                                            </div>

                                            <b-collapse :id="'suturaeOpen'" v-model="suturaeOpen">
                                                <div class="gen-part-body mt-2">
                                                <div>
                                                    <b-form-radio-group :options="Config.mr.mNormalTidakNormalText"
                                                    v-model="row.arankmb_suturae" />
                                                </div>
                                                <div class="gen-input mt-3">
                                                    <div class="form-row">
                                                    <div class="form-group col-md-12">
                                                        <textarea v-model="row.arankmb_suturae_text" class="form-control"
                                                        placeholder="Tuliskan" rows="2"></textarea>
                                                    </div>
                                                    </div>
                                                </div>
                                                <VValidate name="Tipe Suturae" v-model="row.arankmb_suturae"
                                                :rules="{required: 1}" />
                                                
                                                <!--
                                                <VValidate name="Suturae" v-model="row.arankmb_suturae_text"
                                                :rules="{required: 1}" />
                                                -->
                                                </div>
                                            </b-collapse>
                                            </div>


                                            <div class="gen-part">
                                            <div class="d-flex align-items-center justify-content-between gen-part-head"
                                                @click="fontanelOpen = !fontanelOpen" :class="fontanelOpen ? null : 'collapsed'"
                                                :aria-expanded="fontanelOpen ? 'true' : 'false'" aria-controls="fontanelOpen">
                                                <div class="font-weight-semibold">Fontanel</div>
                                                <i class="icon-arrow-down22"></i>
                                            </div>

                                            <b-collapse :id="'fontanelOpen'" v-model="fontanelOpen">
                                                <div class="gen-part-body mt-2">
                                                <div>
                                                    <b-form-radio-group :options="Config.mr.mRanapBBLFontanel"
                                                    v-model="row.arankmb_fontanel" />
                                                </div>
                                                
                                                <VValidate name="Fontanel" v-model="row.arankmb_fontanel"
                                                :rules="{required: 1}" />

                                                </div>
                                            </b-collapse>
                                            </div>

                                            <div class="gen-part">
                                            <div class="d-flex align-items-center justify-content-between gen-part-head"
                                                @click="caputOpen = !caputOpen" :class="caputOpen ? null : 'collapsed'"
                                                :aria-expanded="caputOpen ? 'true' : 'false'" aria-controls="caputOpen">
                                                <div class="font-weight-semibold">Caput Succedaneum</div>
                                                <i class="icon-arrow-down22"></i>
                                            </div>

                                            <b-collapse :id="'caputOpen'" v-model="caputOpen">
                                                <div class="gen-part-body mt-2">
                                                <div>
                                                    <b-form-radio-group :options="Config.mr.mAdaTidakAdaText"
                                                    v-model="row.arankmb_caput" />
                                                </div>
                                                
                                                <VValidate name="Caput" v-model="row.arankmb_caput"
                                                :rules="{required: 1}" />
                                                </div>
                                            </b-collapse>
                                            </div>

                                            <div class="gen-part">
                                            <div class="d-flex align-items-center justify-content-between gen-part-head"
                                                @click="septalOpen = !septalOpen" :class="septalOpen ? null : 'collapsed'"
                                                :aria-expanded="septalOpen ? 'true' : 'false'" aria-controls="septalOpen">
                                                <div class="font-weight-semibold">Septal hematoma</div>
                                                <i class="icon-arrow-down22"></i>
                                            </div>

                                            <b-collapse :id="'septalOpen'" v-model="septalOpen">
                                                <div class="gen-part-body mt-2">
                                                <div>
                                                    <b-form-radio-group :options="Config.mr.mAdaTidakAdaText"
                                                    v-model="row.arankmb_septal_hematoma" />
                                                </div>
                                                <VValidate name="Septal" v-model="row.arankmb_septal_hematoma"
                                                :rules="{required: 1}" />
                                                </div>
                                            </b-collapse>
                                            </div>

                                            <!--
                                            <div class="gen-part">
                                            <div class="d-flex align-items-center justify-content-between gen-part-head"
                                                @click="mataOpen = !mataOpen" :class="mataOpen ? null : 'collapsed'"
                                                :aria-expanded="mataOpen ? 'true' : 'false'" aria-controls="mataOpen">
                                                <div class="font-weight-semibold">Mata</div>
                                                <i class="icon-arrow-down22"></i>
                                            </div
                                            <b-collapse :id="'mataOpen'" v-model="mataOpen">
                                                <div class="gen-part-body mt-2">
                                                <div>
                                                    <b-form-radio-group :options="Config.mr.mAdaTidakAdaText"
                                                    v-model="row.arankmb_mata" />
                                                </div>
                                                <div class="gen-input mt-3">
                                                    <div class="form-row">
                                                    <div class="form-group col-md-12">
                                                        <textarea v-model="row.arankmb_mata_text" class="form-control"
                                                        placeholder="Tuliskan" rows="2"></textarea>
                                                    </div>
                                                    </div>

                                                    <VValidate name="Tipe Suturae" v-model="row.arankmb_suturae"
                                                    :rules="{required: 1}" />
                                                    
                                                    <VValidate name="Suturae" v-model="row.arankmb_suturae_text"
                                                    :rules="{required: 1}" />

                                                </div>
                                                </div>
                                            </b-collapse>
                                            </div>
                                            -->
                                            
                                            <div class="gen-part">
                                            <div class="d-flex align-items-center justify-content-between gen-part-head"
                                                @click="palatumOpen = !palatumOpen" :class="palatumOpen ? null : 'collapsed'"
                                                :aria-expanded="palatumOpen ? 'true' : 'false'" aria-controls="palatumOpen">
                                                <div class="font-weight-semibold">Palatum</div>
                                                <i class="icon-arrow-down22"></i>
                                            </div>

                                            <b-collapse :id="'palatumOpen'" v-model="palatumOpen">
                                                <div class="gen-part-body mt-2">
                                                <div>
                                                    <b-form-radio-group :options="Config.mr.mRanapBBLBibir"
                                                    v-model="row.arankmb_palatum" />
                                                </div>
                                                <VValidate name="Palatum" v-model="row.arankmb_palatum"
                                                :rules="{required: 1}" />
                                                </div>
                                            </b-collapse>
                                            </div>
                                        </div>
                                        <div class="col-md-6">

                                            <div class="gen-part">
                                            <div class="d-flex align-items-center justify-content-between gen-part-head"
                                                @click="telingaOpen = !telingaOpen" :class="telingaOpen ? null : 'collapsed'"
                                                :aria-expanded="telingaOpen ? 'true' : 'false'" aria-controls="telingaOpen">
                                                <div class="font-weight-semibold">Telinga</div>
                                                <i class="icon-arrow-down22"></i>
                                            </div>

                                            <b-collapse :id="'telingaOpen'" v-model="telingaOpen">
                                                <div class="gen-part-body mt-2">
                                                <div>
                                                    <b-form-radio-group :options="Config.mr.mRanapBBLTelinga"
                                                    v-model="row.arankmb_telinga" />
                                                </div>
                                                <VValidate name="Tipe Telinga" v-model="row.arankmb_telinga"
                                                :rules="{required: 1}" />
                                                
                                                </div>
                                            </b-collapse>
                                            </div>

                                            <div class="gen-part">
                                            <div class="d-flex align-items-center justify-content-between gen-part-head"
                                                @click="hidungOpen = !hidungOpen" :class="hidungOpen ? null : 'collapsed'"
                                                :aria-expanded="hidungOpen ? 'true' : 'false'" aria-controls="hidungOpen">
                                                <div class="font-weight-semibold">Hidung</div>
                                                <i class="icon-arrow-down22"></i>
                                            </div>

                                            <b-collapse :id="'hidungOpen'" v-model="hidungOpen">
                                                <div class="gen-part-body mt-2">
                                                <div>
                                                    <b-form-radio-group :options="Config.mr.mRanapBBLHidung"
                                                    v-model="row.arankmb_hidung" />
                                                </div>
                                                <VValidate name="Hidung" v-model="row.arankmb_hidung"
                                                :rules="{required: 1}" />
                                                </div>
                                            </b-collapse>
                                            </div>

                                            <div class="gen-part">
                                            <div class="d-flex align-items-center justify-content-between gen-part-head"
                                                @click="mulutOpen = !mulutOpen" :class="mulutOpen ? null : 'collapsed'"
                                                :aria-expanded="mulutOpen ? 'true' : 'false'" aria-controls="mulutOpen">
                                                <div class="font-weight-semibold">Mulut</div>
                                                <i class="icon-arrow-down22"></i>
                                            </div>

                                            <b-collapse :id="'mulutOpen'" v-model="mulutOpen">
                                                <div class="gen-part-body mt-2">
                                                <div>
                                                    <b-form-radio-group :options="Config.mr.mRanapBBLMulut"
                                                    v-model="row.arankmb_mulut" />
                                                </div>
                                                <VValidate name="Mulut" v-model="row.arankmb_mulut"
                                                :rules="{required: 1}" />
                                                </div>
                                            </b-collapse>
                                            </div>

                                            <div class="gen-part">
                                            <div class="d-flex align-items-center justify-content-between gen-part-head"
                                                @click="bibirOpen = !bibirOpen" :class="bibirOpen ? null : 'collapsed'"
                                                :aria-expanded="bibirOpen ? 'true' : 'false'" aria-controls="bibirOpen">
                                                <div class="font-weight-semibold">Bibir</div>
                                                <i class="icon-arrow-down22"></i>
                                            </div>

                                            <b-collapse :id="'bibirOpen'" v-model="bibirOpen">
                                                <div class="gen-part-body mt-2">
                                                <div>
                                                    <b-form-radio-group :options="Config.mr.mRanapBBLBibir"
                                                    v-model="row.arankmb_bibir" />
                                                </div>
                                                <VValidate name="Bibir" v-model="row.arankmb_bibir"
                                                :rules="{required: 1}" />
                                                </div>
                                            </b-collapse>
                                            </div>

                                            <div class="gen-part">
                                            <div class="d-flex align-items-center justify-content-between gen-part-head"
                                                @click="lidahOpen = !lidahOpen" :class="lidahOpen ? null : 'collapsed'"
                                                :aria-expanded="lidahOpen ? 'true' : 'false'" aria-controls="lidahOpen">
                                                <div class="font-weight-semibold">Lidah</div>
                                                <i class="icon-arrow-down22"></i>
                                            </div>

                                            <b-collapse :id="'lidahOpen'" v-model="lidahOpen">
                                                <div class="gen-part-body mt-2">
                                                <div>
                                                    <b-form-radio-group :options="Config.mr.mRanapBBLLidah"
                                                    v-model="row.arankmb_lidah" />
                                                </div>
                                                <VValidate name="Lidah" v-model="row.arankmb_lidah"
                                                :rules="{required: 1}" />
                                                </div>
                                            </b-collapse>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="row">
                                    <div class="col-md-12">
                                        <div class="card mb-2">
                                        <div class="card-header bg_head_panel">
                                            <h6 class="card-title font-weight-semibold">Leher <span class="text-danger">*</span></h6>
                                        </div>
                                        <div class="card-body p-3">
                                            <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                <div class="mb-2">
                                                    <label for="Bentuk">Bentuk </label>
                                                    <v-select placeholder="--Pilih Item--" v-model="row.arankmb_bentuk_leher"
                                                        :options="Config.mr.mRanapBBLLeher" label="text" :reduce="v=>v.value">
                                                    </v-select>
                                                    <VValidate name="Bentuk" v-model="row.arankmb_bentuk_leher"
                                                    :rules="{required: 1}" />
                                                </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>

                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="card mb-2">
                                    <div class="card-header bg_head_panel">
                                        <h6 class="card-title font-weight-semibold">Dada</h6>
                                    </div>
                                    <div class="card-body p-3">
                                        <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                            <div class="mb-2">
                                                <label for="Pemeriksaan">Pemeriksaan </label>
                                                <v-select placeholder="--Pilih Item--" v-model="row.arankmb_dada_pemeriksaan"
                                                    :options="Config.mr.mRanapBBLPemeriksaan" label="text" :reduce="v=>v.value">
                                                </v-select>
                                                
                                                <!--
                                                <VValidate name="Dada" v-model="row.arankmb_dada_pemeriksaan"
                                                :rules="{required: 1}" />
                                                -->
                                            </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                            <div class="mb-2">
                                                <label for="Paruparu">Paru-paru </label>
                                                <v-select placeholder="--Pilih Item--" v-model="row.arankmb_dada_paru"
                                                    :options="Config.mr.mRanapBBLParu" label="text" :reduce="v=>v.value">
                                                </v-select>
                                                
                                                <!--
                                                <VValidate name="Paru-Paru" v-model="row.arankmb_dada_paru"
                                                :rules="{required: 1}" />
                                                -->
                                            </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                            <div class="mb-2">
                                                <label for="Jantung">Jantung</label>
                                                <v-select placeholder="--Pilih Item--" v-model="row.arankmb_dada_jantung"
                                                    :options="Config.mr.mRanapBBLJantung" label="text" :reduce="v=>v.value">
                                                </v-select>
                                                
                                                <!--
                                                <VValidate name="Jantung" v-model="row.arankmb_dada_jantung"
                                                :rules="{required: 1}" />
                                                -->
                                            </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                            <div class="mb-2">
                                                <label for="Perut">Perut</label>
                                                <v-select placeholder="--Pilih Item--" v-model="row.arankmb_dada_perut"
                                                    :options="Config.mr.mRanapBBLPerut" label="text" :reduce="v=>v.value">
                                                </v-select>

                                                <!--
                                                <VValidate name="Perut" v-model="row.arankmb_dada_perut"
                                                :rules="{required: 1}" />
                                                -->
                                            </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                            <div class="mb-2">
                                                <label for="TaliPusat">Tali Pusat</label>
                                                <v-select placeholder="--Pilih Item--" v-model="row.arankmb_dada_tali_pusat"
                                                    :options="Config.mr.mRanapBaikLayu" label="text" :reduce="v=>v.value">
                                                </v-select>
                                                
                                                <!--                                                
                                                <VValidate name="Tali Pusat" v-model="row.arankmb_dada_tali_pusat"
                                                :rules="{required: 1}" />
                                                -->
                                            </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                            <div class="mb-2">
                                                <label for="AnusRectum">Anus Rectum</label>
                                                <v-select placeholder="--Pilih Item--" v-model="row.arankmb_dada_anus_rectum"
                                                    :options="Config.mr.mAnusLubang" label="text" :reduce="v=>v.value">
                                                </v-select>

                                                <!--                                                  
                                                <VValidate name="Anus Rectum" v-model="row.arankmb_dada_anus_rectum"
                                                :rules="{required: 1}" />
                                                -->
                                            </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                            <div class="mb-2">
                                                <label for="AnggotaGerak">Anggota Gerak</label>
                                                <v-select placeholder="--Pilih Item--" v-model="row.arankmb_dada_anggota_gerak"
                                                    :options="Config.mr.mRanapAnggotaGerak" label="text" :reduce="v=>v.value">
                                                </v-select>
                                                
                                                <!--                                                  <VValidate name="Anggota Gerak" v-model="row.arankmb_dada_anggota_gerak"
                                                :rules="{required: 1}" />
                                                -->
                                            </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                            <div class="mb-2">
                                                <label for="Kelainan">Kelainan lain-lain</label>
                                                <b-form-input v-model="row.arankmb_dada_kelainan_lain" :formatter="normalText" type="text"
                                                class="form-control" />
                                                
                                                <!--
                                                <VValidate name="Kelainan lain-lain" v-model="row.arankmb_dada_kelainan_lain"
                                                :rules="{required: 1}" />
                                                -->
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="row mt-2">
                                <div class="col-md-8">
                                <div class="card border shadow-0">
                                    <div class="bg-light card-header py-2">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <h6 class="card-title mb-0 font-weight-semibold">Diagnosis</h6>
                                        <a href="javascript:;" v-if="riwayatICD10.length" @click="openriwayatICD10 = true" class="btn btn-sm alpha-orange border-orange text-orange-800">
                                        <i class="icon-list mr-2 align-middle"></i><span class="align-middle">Pilih dari Riwayat</span>
                                        </a>
                                    </div>
                                    </div>
                                    <div class="card-body">
                                    <div class="form-group mb-0">
                                        <div class="input-group">
                                        <div class="form-group form-group-feedback form-group-feedback-left mb-0">
                                            <v-select class="diagnosa-section select-paging" placeholder="Pilih Diagnosa"
                                                v-model="inputICD10"
                                                @search="filterICD10($event,0)"
                                                @input="selectICD10(inputICD10)"
                                                :options="mICD10" label="text"
                                                :clearable="true" :reduce="v=>v.value">
                                                
                                                <template slot="selected-option">
                                                <span v-b-tooltip.hover.right :title="inputICD10Name">
                                                    {{inputICD10Name||"-"}}
                                                </span>
                                                </template>
                                                
                                                <template slot="option" slot-scope="option">
                                                <span v-b-tooltip.hover.right :title="option.text">{{ option.text }}</span>
                                                </template>
                                                <template #list-footer v-if="(mICD10||[]).length >= 1">
                                                <li class="list-paging">
                                                    <div class="d-flex align-items-center justify-content-between">
                                                    <b-button @click="changePageICD10(mICD10[0].currentPage,0,'min','mICD10')"
                                                        size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                                                        class="icon-arrow-left22 text-white"></i></b-button>
                                                    <small>Halaman {{mICD10[0].currentPage}} dari
                                                        {{ceilData(mICD10[0].totalRows/5)}}</small>
                                                    <b-button @click="changePageICD10(mICD10[0].currentPage,0,'plus','mICD10')"
                                                        size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                                                        class="icon-arrow-right22 text-white"></i></b-button>
                                                    </div>
                                                </li>
                                                </template>
                                            </v-select>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    <table class="table table-bordered table-sm" v-if="(dataICD10||[]).length">
                                    <thead>
                                        <tr>
                                        <th>Item ICD-10 dipilih</th>
                                        <th width="64">Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(v,k) in (dataICD10||[])" :key="k">
                                        <td><span>{{v.mi10_name||"-"}}<span class="font-weight-semibold">[{{v.mi10_code||"-"}}]</span></span></td>
                                        <td>
                                            <a href="javascript:;" @click="removedataICD10(v.arankai10d_id, k)" class="btn btn-sm btn-icon alpha-danger text-danger-800 border-danger">
                                            <i class="icon-trash-alt"></i>
                                            </a>
                                        </td>
                                        </tr>
                                    </tbody>
                                    </table>
                                    <div class="card-body py-2" v-else>
                                    <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-10-CM dipilih</label>
                                    </div>
                                    <div class="card-body pt-2">
                                    <div class="form-group mb-0">
                                        <label for="inputManualDiagnose">
                                        Diagnosa Lainnya <strong class="txt_mandatory" v-if="!(dataICD10||[]).length">*</strong>
                                        <span style="cursor:help;" data-popup="tooltip" v-b-tooltip.hover.right title="silakan isi kolom dibawah untuk mengisi diagnosa secara manual"><i class="icon-info22 text-indigo"></i></span>
                                        </label>
                                        <b-textarea v-model="row.arankmb_diagnosa_text" name=""
                                        id="" rows="3" class="form-control"></b-textarea>
                                        
                                    <VValidate v-if="!(dataICD10||[]).length" :name="`Diagnosa`" v-model="row.arankmb_diagnosa_text"
                                        :rules="{required:1, min:2, max:512}" />
                                    </div>
                                    </div>
                                </div>
                                </div>
                                
                                <!--
                                <div class="col-md-6">
                                <div class="card border shadow-0">
                                    <div class="bg-light card-header py-2">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <h6 class="card-title mb-0 font-weight-semibold">Tindakan</h6>
                                        <a href="javascript:;" v-if="riwayatICD9.length" @click="openriwayatICD9 = true" class="btn btn-sm alpha-orange border-orange text-orange-800">
                                        <i class="icon-list mr-2 align-middle"></i><span class="align-middle">Pilih dari Riwayat</span>
                                        </a>
                                    </div>
                                    </div>
                                    <div class="card-body">
                                    <div class="form-group mb-0">
                                        <div class="input-group">
                                        <div class="form-group form-group-feedback form-group-feedback-left mb-0">
                                            <v-select class="diagnosa-section select-paging" placeholder="Pilih Tindakan"
                                                v-model="inputICD9"
                                                @search="filterICD9($event,0)"
                                                @input="selectICD9(inputICD9)"
                                                :options="mICD9" label="text"
                                                :clearable="true" :reduce="v=>v.value">
                                                
                                                <template slot="selected-option">
                                                <span v-b-tooltip.hover.right :title="inputICD9Name">
                                                    {{inputICD9Name||"-"}}
                                                </span>
                                                </template>
                                                
                                                <template slot="option" slot-scope="option">
                                                <span v-b-tooltip.hover.right :title="option.text">{{ option.text }}</span>
                                                </template>
                                                <template #list-footer v-if="(mICD9||[]).length >= 1">
                                                <li class="list-paging">
                                                    <div class="d-flex align-items-center justify-content-between">
                                                    <b-button @click="changePageICD9(mICD9[0].currentPage,0,'min','mICD9')"
                                                        size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                                                        class="icon-arrow-left22 text-white"></i></b-button>
                                                    <small>Halaman {{mICD9[0].currentPage}} dari
                                                        {{ceilData(mICD9[0].totalRows/5)}}</small>
                                                    <b-button @click="changePageICD9(mICD9[0].currentPage,0,'plus','mICD9')"
                                                        size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                                                        class="icon-arrow-right22 text-white"></i></b-button>
                                                    </div>
                                                </li>
                                                </template>
                                            </v-select>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    <table class="table table-bordered table-sm" v-if="(dataICD9||[]).length">
                                    <thead>
                                        <tr>
                                        <th>Item ICD-9 dipilih</th>
                                        <th width="64">Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(v,k) in (dataICD9||[])" :key="k">
                                        <td>
                                        <span>{{v.mi9_name||"-"}}<span class="font-weight-semibold" v-if="v.mi9_code">[{{v.mi9_code||"-"}}]</span></span>
                                        <div v-if="v.mi9_informed_consent"><small class="font-weight-medium text-info-800">Tindakan memerlukan informed consent</small></div>
                                        </td>
                                        <td>
                                            <a href="javascript:;" @click="removedataICD9(v.arankai9d_id, k)" class="btn btn-sm btn-icon alpha-danger text-danger-800 border-danger" v-b-tooltip.hover title="Hapus Data ICD 9">
                                            <i class="icon-trash-alt"></i>
                                            </a>
                                            
                                            <a href="javascript:;" @click="openInformConsent(v)" class="btn btn-sm btn-icon alpha-indigo text-indigo-800 border-indigo"  v-b-tooltip.hover :title="v.arankai9d_informed_consent ? 'Edit Informed Consent':'Tambah Informed Consent'">
                                            <i class="icon-file-check2"></i>
                                            </a>

                                            <a href="javascript:;" @click="openInformConsentDetail(v)" v-if="v.arankai9d_informed_consent" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal"  v-b-tooltip.hover title="Lihat Informed Consent">
                                            <i class="icon-file-eye2"></i>
                                            </a>
                                        </td>
                                        </tr>
                                    </tbody>
                                    </table>
                                    <div class="card-body py-2" v-else>
                                    <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-9-CM dipilih</label>
                                    </div>
                                    <div class="card-body pt-2">
                                    <div class="form-group mb-0">
                                        <label for="inputManualDiagnose">
                                        Tindakan Lainnya
                                        <span style="cursor:help;" data-popup="tooltip" v-b-tooltip.hover.right title="silakan isi kolom dibawah untuk mengisi tindakan secara manual"><i class="icon-info22 text-indigo"></i></span>
                                        </label>
                                        <b-textarea v-model="row.arankmb_tindakan_text" name=""
                                        id="" rows="3" class="form-control"></b-textarea>

                                        <a href="javascript:;" v-if="row.arankmb_tindakan_text" @click="openInformConsentForm"  class="btn btn-sm mr-2 mt-2 btn-icon alpha-indigo text-indigo-800 border-indigo"><i class="icon-file-check2"></i> 
                                        {{row.arankmb_inform_consent_id ? 'Edit Informed Consent' : 'Tambah Informed Consent'}}
                                        </a>
                                        
                                        <a href="javascript:;" @click="openInformConsentDetailForm" v-if="row.arankmb_inform_consent_id" class="btn btn-sm mr-2 mt-2 btn-icon alpha-indigo text-indigo-800 border-indigo"><i class="icon-file-check2"></i> Lihat Inform Consent</a>

                                        <a href="javascript:;" v-if="row.arankmb_inform_consent_id" @click="removeICDForm()" class="btn btn-sm mr-2 mt-2 btn-icon alpha-danger text-danger-800 border-danger"><i class="icon-trash-alt"></i> Hapus Inform Consent</a>
                                    </div>
                                    </div>
                                </div>
                                </div>
                                -->
                            </div>

                            <div class="row mt-2">
                                <div class="col-md-8">
                                    <div class="card border shadow-0">
                                        <div class="bg-light card-header py-2">
                                            <div class="d-flex align-items-center justify-content-between">
                                                <h6 class="card-title mb-0 font-weight-semibold">RENCANA PENGOBATAN DAN TINDAKAN</h6>
                                            </div>
                                        </div>
                                        <div class="form-group mb-0 p-3">
                                            <b-textarea v-model="row.arankmb_rencana_tindakan" name=""
                                            id="" rows="3" class="form-control"></b-textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="card border shadow-0" v-if="row.isEdit">
                                <div class="card-header bg-info">
                                    <h5 class="card-title font-weight-semibold">Keterangan Perubahan</h5>
                                </div>
                                <div class="card-body">
                                    <div class="form-group">
                                        <label for="addInfoTindakan">Keterangan Perubahan</label>
                                        <b-textarea v-model="row.aranrm_notes" :formatter="normalText" rows="6" class="form-control"
                                            placeholder="Keterangan Perubahan">
                                        </b-textarea>

                                        <!--
                                        <VValidate :name="`Keterangan Perubahan`" v-model="row.aranrm_notes"
                                            :rules="{min: 2, max:512}" />
                                        -->
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="card-footer">
                        <div class="text-right">
                            <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>
                            <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
                        </div>
                    </div>
                </div>
                </b-form>
            </validation-observer>
        </div>

        
        <b-modal v-model="openriwayatICD10" :title="'Pilih ICD10 dari Riwayat Pasien'" size="xl" hide-footer>
        <div class="modal-body p-0">
            <div class="mb-3">
            <div class="row">
                <div class="col-md-5 col-lg-4">
                <div class="d-flex w-100">
                    <div class="form-group form-group-feedback form-group-feedback-left flex-fill mb-0 mr-2">
                    <input v-model="searchICD10" @input="searchICD10Data" class="form-control"
                        placeholder="Ketik Nama Dokter / No. Registrasi" />
                    <div class="form-control-feedback">
                        <i class="icon-search4 text-indigo"></i>
                    </div>
                    </div>
                    <button @click="resetData()" class="btn alpha-warning border-orange text-warning-600">Reset</button>
                </div>
                </div>
            </div>
            </div>
            <div class="table-responsive">
            <table class="table table-bordered table-sm patient-table">
                <thead>
                <tr>
                    <th width="64">#</th>
                    <th>No. Registrasi</th>
                    <th>Tanggal</th>
                    <th>Dokter</th>
                    <th>ICD10</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(v,k) in riwayatICD10">
                    <tr :class="selectedICD10 == v.arm_id ? 'table-info' : ''" :key="k+'diagnosa'">
                    <td v-if="v.isHead" :rowspan="v.rowspan">
                        <div class="custom-control custom-control-inline custom-checkbox">
                        <input type="radio" :value="v.arm_id" v-model="selectedICD10" name="historySelectionDiagnose"
                            :id="`histdiag${k+1}`" class="custom-control-input">
                        <label :for="`histdiag${k+1}`" class="custom-control-label"></label>
                        </div>

                    </td>

                    <td v-if="v.isHead" :rowspan="v.rowspan">
                        <span class="font-weight-semibold">{{v.ar_reg_code||"-"}}</span>
                    </td>
                    <td v-if="v.isHead" :rowspan="v.rowspan">{{v.ar_reg_date | moment("DD MMM YYYY")}}</td>
                    <td v-if="v.isHead" :rowspan="v.rowspan"><strong>{{v.bu_full_name||"-"}}
                        <small><br>{{v.mpo_name||"-"}}</small></strong></td>
                    <td>{{v.mi10_name||"-"}},{{v.api10_diagnosis||"-"}}</td>
                    </tr>
                </template>
                <tr v-if="!(riwayatICD10||[]).length">
                    <td colspan="99" class="text-center">Tidak ada data</td>
                </tr>
                </tbody>
            </table>
            </div>
        </div>
        <div class="modal-footer p-0 mt-3">
            <div class="text-right">
            <button data-dismiss="modal" @click="openriwayatICD10 = false" class="btn">Batal</button>
            <button data-dismiss="modal" @click="saveICD10" :disabled="!selectedICD10"
                class="btn btn-success btn-labeled btn-labeled-left"><b><i class="icon-checkmark"></i></b>Simpan
                Pilihan</button>
            </div>
        </div>
        </b-modal>

        <b-modal v-model="openriwayatICD9" :title="'Pilih ICD9 dari Riwayat Pasien'" size="xl" hide-footer>
        <div class="modal-body p-0">
            <div class="mb-3">
            <div class="row">
                <div class="col-md-5 col-lg-4">
                <div class="d-flex w-100">
                    <div class="form-group form-group-feedback form-group-feedback-left flex-fill mb-0 mr-2">
                    <input v-model="searchICD9" @input="searchICD9Data" class="form-control"
                        placeholder="Ketik Nama Dokter / No. Registrasi" />
                    <div class="form-control-feedback">
                        <i class="icon-search4 text-indigo"></i>
                    </div>
                    </div>
                    <button @click="resetData()" class="btn alpha-warning border-orange text-warning-600">Reset</button>
                </div>
                </div>
            </div>
            </div>
            <div class="table-responsive">
            <table class="table table-bordered table-sm patient-table">
                <thead>
                <tr>
                    <th width="64">#</th>
                    <th>No. Registrasi</th>
                    <th>Tanggal</th>
                    <th>Dokter</th>
                    <th>ICD9</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(v,k) in riwayatICD9">

                    <tr :class="selectedICD9 == v.arm_id ? 'table-info' : ''" :key="k+'diagnosa'">
                    <td v-if="v.isHead" :rowspan="v.rowspan">
                        <div class="custom-control custom-control-inline custom-checkbox">
                        <input type="radio" :value="v.arm_id" v-model="selectedICD9" name="historySelectionDiagnose"
                            :id="`histdiag${k+1}`" class="custom-control-input">
                        <label :for="`histdiag${k+1}`" class="custom-control-label"></label>
                        </div>

                    </td>

                    <td v-if="v.isHead" :rowspan="v.rowspan">
                        <span class="font-weight-semibold">{{v.ar_reg_code||"-"}}</span>
                    </td>
                    <td v-if="v.isHead" :rowspan="v.rowspan">{{v.ar_reg_date | moment("DD MMM YYYY")}}</td>
                    <td v-if="v.isHead" :rowspan="v.rowspan"><strong>{{v.bu_full_name||"-"}}
                        <small><br>{{v.mpo_name||"-"}}</small></strong></td>
                    <td>{{v.mi9_name||"-"}},{{v.api9_tindakan||"-"}}</td>
                    </tr>
                </template>
                <tr v-if="!(riwayatICD9||[]).length">
                    <td colspan="99" class="text-center">Tidak ada data</td>
                </tr>
                </tbody>
            </table>
            </div>
        </div>
        <div class="modal-footer p-0 mt-3">
            <div class="text-right">
            <button data-dismiss="modal" @click="openriwayatICD9 = false" class="btn">Batal</button>
            <button data-dismiss="modal" @click="saveICD9" :disabled="!selectedICD9"
                class="btn btn-success btn-labeled btn-labeled-left"><b><i class="icon-checkmark"></i></b>Simpan
                Pilihan</button>
            </div>
        </div>
        </b-modal>

        <validation-observer ref="VFormInformconsent">
        <b-modal v-model="openModalIC" :title="'Tambahkan Informed Consent'" size="lg" @ok.prevent="submitInformConcent">
        <table class="table table-bordered table-sm table-input">
            <thead>
            <tr>
                <td colspan="3">
                <div class="result_tab">
                    <h4>Nama Tindakan / ICD-9-CM</h4>
                    <p>{{rowIC.mi9_name}} <span v-if="rowIC.mi9_code" class="font-weight-semibold">[{{rowIC.mi9_code}}]</span></p>
                </div>
                </td>
            </tr>
            <tr>
                <th width="24">No.</th>
                <th>Jenis Informasi</th>
                <th>Isi Informasi</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>1</td>
                <td>Diagnosis<br/>(Diagnosis Kerja & Diagnosis Banding)</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_diagnosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>2</td>
                <td>Dasar Diagnosis</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_dasar_diagnosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>3</td>
                <td>Tindakan Kedokteran</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_tindakan_dokter" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>4</td>
                <td>Indikasi Tindakan</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_indikasi_tindakan" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>5</td>
                <td>Tata Cara</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_tata_cara" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>6</td>
                <td>Tujuan</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_tujuan" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>7</td>
                <td>Risiko</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_risiko" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>8</td>
                <td>Komplikasi</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_kompilasi" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>9</td>
                <td>Prognosis</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_prognosis" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>10</td>
                <td>Alternatif & Risiko</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_alternatif" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            <tr>
                <td>11</td>
                <td>Lain-lain</td>
                <td class="input-cell">
                <textarea v-model="rowIC.aranic_lainnya" rows="2" placeholder="Informasi Terkait" class="form-control"></textarea>
                </td>
            </tr>
            </tbody>
            <tfoot>
                <tr>
                <td width="50%" colspan="2">
                    <div>
                    <label for="">Apakah pasien/pendamping pasien menyetujui tindakan? <strong class="text-danger">*</strong></label>
                    <div>
                        <b-form-radio-group
                            :options="Config.mr.pasienSetuju"
                            v-model="rowIC.aranic_is_pasien_setuju"
                        />
                        <VValidate :name="`Persetujuan Pasien`" v-model="rowIC.aranic_is_pasien_setuju" :rules="{required:1}" />
                    </div>
                    </div>
                </td>
                <td width="50%">
                    <div>
                    <label for="">Persetujuan tindakan diberikan kepada <strong class="text-danger">*</strong></label>
                    <div>
                        <b-form-radio-group
                            :options="Config.mr.persetujuanTindakan"
                            v-model="rowIC.aranic_persetujuan_tindakan"
                        />
                        <VValidate :name="`Persetujuan Tindakan Kepada`" v-model="rowIC.aranic_persetujuan_tindakan" :rules="{required:1}" />
                    </div>
                    </div>
                </td>
                </tr>
            </tfoot>
        </table>
        </b-modal>
        </validation-observer>

        <b-modal v-model="openModalICDetail" :title="'Detail Informed Consent'" size="lg" ok-only>
            <table class="table table-bordered table-sm table-input">
            <thead>
                <tr>
                <td colspan="3">
                    <div class="result_tab">
                        <h4>Nama Tindakan / ICD-9-CM</h4>
                        <p>{{rowIC.mi9_name}} <span v-if="rowIC.mi9_code" class="font-weight-semibold">[{{rowIC.mi9_code}}]</span></p>
                    </div>
                </td>
                </tr>
                <tr>
                <th width="24">No.</th>
                <th>Jenis Informasi</th>
                <th>Isi Informasi</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                <td>1</td>
                <td>Diagnosis<br />(Diagnosis Kerja & Diagnosis Banding)</td>
                <td>
                {{rowIC.aranic_diagnosis||"-"}}
                </td>
                </tr>
                <tr>
                <td>2</td>
                <td>Dasar Diagnosis</td>
                <td>
                {{rowIC.aranic_dasar_diagnosis||"-"}}
                </td>
                </tr>
                <tr>
                <td>3</td>
                <td>Tindakan Kedokteran</td>
                <td>
                {{rowIC.aranic_tindakan_dokter||"-"}}
                </td>
                </tr>
                <tr>
                <td>4</td>
                <td>Indikasi Tindakan</td>
                <td>
                {{rowIC.aranic_indikasi_tindakan||"-"}}
                </td>
                </tr>
                <tr>
                <td>5</td>
                <td>Tata Cara</td>
                <td>
                {{rowIC.aranic_tata_cara||"-"}}
                </td>
                </tr>
                <tr>
                <td>6</td>
                <td>Tujuan</td>
                <td>
                {{rowIC.aranic_tujuan||"-"}}
                </td>
                </tr>
                <tr>
                <td>7</td>
                <td>Risiko</td>
                <td>
                {{rowIC.aranic_risiko||"-"}}
                </td>
                </tr>
                <tr>
                <td>8</td>
                <td>Komplikasi</td>
                <td>
                {{rowIC.aranic_kompilasi||"-"}}
                </td>
                </tr>
                <tr>
                <td>9</td>
                <td>Prognosis</td>
                <td>
                {{rowIC.aranic_prognosis||"-"}}
                </td>
                </tr>
                <tr>
                <td>10</td>
                <td>Alternatif & Risiko</td>
                <td>
                {{rowIC.aranic_alternatif||"-"}}
                </td>
                </tr>
                <tr>
                <td>11</td>
                <td>Lain-lain</td>
                <td>
                {{rowIC.aranic_lainnya||"-"}}
                </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                <td width="50%" colspan="2">
                    <div class="result_tab">
                    <h4>Apakah pasien/pendamping pasien menyetujui tindakan?</h4>
                    <p v-if="rowIC.aranic_is_pasien_setuju == 'Y'">Tindakan disetujui</p>
                    <p v-else>Tindakan ditolak</p>
                    </div>
                </td>
                <td width="50%">
                    <div class="result_tab">
                    <h4>Persetujuan tindakan diberikan kepada</h4>
                    <p v-if="rowIC.aranic_persetujuan_tindakan == 'P'">Pasien</p>
                    <p v-else>Penanggung Jawab</p>
                    </div>
                </td>
                </tr>
            </tfoot>
            </table>
        </b-modal>
    </div>
</template>


<script>
import GlobalVue from '@/libs/Global.vue'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'

import PatientInfo from '@/components/Ranap/PatientInfo.vue'


export default {
    extends: GlobalVue,
    components: {PatientInfo},
    data(){
        return {
            rowReg: {},
            
            bentukOpen: false,
            suturaeOpen: false,
            fontanelOpen: false,
            caputOpen: false,
            septalOpen: false,
            mataOpen: false,

            telingaOpen: false,
            hidungOpen: false,
            mulutOpen: false,
            bibirOpen: false,
            lidahOpen: false,
            palatumOpen: false,

            dataICD9: [],
            dataICD10: [],
            riwayatICD9: [],
            riwayatICD10: [],
            
            mICD9:[],
            mICD10:[], 

            openriwayatICD10: false,
            openriwayatICD9: false,
            
            selectedICD9: '',
            selectedICD10: '',
            
            searchICD10: '',
            searchICD9: '',
            
            inputICD9: null,
            inputICD10: null,
            
            inputICD9Name: null,
            inputICD10Name: null,
            searchICD10Name: null,
            searchICD9Name: null,

            rowIC : {
                aranic_is_pasien_setuju: null,
                aranic_persetujuan_tindakan: null
            },

            openModalIC : false,
            openModalICDetail : false,
            
            patientData: {},
            loading: {
                patientInfo: false,
            },

            disabled: {
                'anak-ke': false,
                'jenis-kelamin': false,
                'berat-badan': false,
                'panjang-badan': false,
                'lingkar-kepala': false,
                'lingkar-dada': false,
                'nadi': false,
                'rr': false,
                'suhu-badan': false
            }
        }
    },

    methods: {
        onFocus(v, field){
            const regId = this.$route.query.regId
            console.log(v)
            const payload = {
            to: 'kajian-awal-bbl',
            type: 'set-disable',
            field: field,
            regId: regId
            }
            this.$socket.emit('collaborate', payload)
        },
        onBlur(v, field){
            const regId = this.$route.query.regId
            const payload = {
            to: 'kajian-awal-bbl',
            type: 'set-enable',
            field: field,
            regId: regId
            }
            this.$socket.emit('collaborate', payload)
        },
        onInput: _.debounce(function(v, field){
            const regId = this.$route.query.regId
            const payload = {
                to: 'kajian-awal-bbl',
                type: 'set-value',
                field: field,
                regId: regId,
                value: v
            }

            this.$socket.emit('collaborate', payload)
        }, 500),
        
        back() {
            this.$router.back()
        },

        toValidate(val){
            return {...val}
        },
        inputData(field){
            console.log(field)
            console.log("for realtime")
        }, 
        apiGet(params = {}, page = 1){
            if(!this.pageSlug || !this.$route.query.regId){
                this.$router.push({name : 'Dashboard'}).catch(()=>{})
            }

            if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
            this.data.data = false
            let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
            let url = this.$route.params.rmNo ? this.modulePage+'/'+this.$route.params.pageSlug+'/'+this.$route.params.rmNo : this.modulePage+'/'+this.$route.params.pageSlug
            
            Gen.apiRest(
                "/get/"+url, 
                {
                params: Object.assign({page: page}, paramsQuery, params.query||{})
                }
            ).then(res=>{
                this.loadingOverlay = false
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
                this.pageNow = page
                this.last_page = this.data.last_page
                
                if(!this.isFound){
                    this.$router.push({name : 'Dashboard'}).catch(()=>{})
                }
            }).catch(()=>{
                this.loadingOverlay = false
            })

            // master
            if(this.$route.params.rmNo){
                Gen.apiRest(
                    "/get/"+url+'?master=1', 
                    {
                    params: Object.assign({page: page}, paramsQuery, params.query||{})
                    }
                ).then(res=>{
                    console.log(res.data)
                    _.forEach(res.data, (v,k)=>{
                        this.$set(this, k, v)
                    })
                })
            }
            
        },
        
        getPatientInfo() {
            this.$set(this.loading, 'patientInfo', true)
            Gen.apiRest(
            "/do/" + 'RanapPasienPerawatan', {
                data: {
                type: "get-data-pasien",
                id_registrasi: this.$route.query.regId
                }
            },
            "POST"
            ).then(resp => {
            this.$set(this, 'patientData', resp.data.data)

            this.$set(this.loading, 'patientInfo', false)
            })
        },

        getConfigDynamic(master,value){
            let text = ''
            if(value){
                let index = (master||[]).findIndex(x => x.value == value)
                if(index !== -1){
                    text = master[index]['text']
                }
            }
            return text
        },
        
        autoSave: _.debounce(function (data) {
            data.type = 'auto-save'
            data.aranrm_last_step = this.row.aranrm_last_step

            if(!data.isEdit){
                Gen.apiRest(
                    "/do/"+this.modulePage,
                    {data:data}, 
                    "POST"
                )
            }
        },1000),

        // Assesmen Start
        
        removeICDForm(){
          
            this.$swal({
                icon: 'warning',
                title: 'Apakah Anda Yakin akan menghapus data ini?',
                showCancelButton: true,
                confirmButtonText: 'Ya',
                cancelButtonText: 'Tidak, kembali'
            }).then(result => {  
              this.loadingOverlay = true
              Gen.apiRest('/do/' + this.modulePage, {
                  data: {
                    type: 'remove-ic',
                    id: this.row.arankmb_inform_consent_id
                  }
              }, 'POST').then(res => {
                  this.row.arankmb_inform_consent_id = null
                  this.loadingOverlay = false
              })
            })
        },
        openInformConsentDetail(v){
            Gen.apiRest('/do/' + this.modulePage, {
                data: {
                    type: 'get-data-ic',
                    id: v.arankai9d_informed_consent,
                }
            }, 'POST').then(res => {
                this.rowIC = res.data.row 
                this.rowIC.mi9_name = v.mi9_name
                this.rowIC.mi9_code = v.mi9_code
                this.rowIC.id_icd = v.arankai9d_id
                this.openModalICDetail = true                   
            })
        },
        openInformConsent(v){
            if(!v.arankai9d_informed_consent){              
                this.rowIC.aranic_persetujuan_tindakan = null
                this.rowIC.aranic_is_pasien_setuju = null

                if(v.mi9_informed_consent){
                    Gen.apiRest('/do/' + this.modulePage, {
                        data: {
                            type: 'autofill-ic',
                            id: v.mi9_informed_consent,
                        }
                    }, 'POST').then(res => {
                        this.rowIC = res.data.row
                        this.rowIC.mi9_name = v.mi9_name
                        this.rowIC.mi9_code = v.mi9_code
                        this.rowIC.id_icd = v.arankai9d_id
                        this.rowIC.aranic_id = null
                    })
                }else{
                  this.rowIC.aranic_diagnosis = null
                  this.rowIC.aranic_id = null
                  this.rowIC.aranic_dasar_diagnosis = null
                  this.rowIC.aranic_tindakan_dokter = null
                  this.rowIC.aranic_indikasi_tindakan = null
                  this.rowIC.aranic_tata_cara = null
                  this.rowIC.aranic_tujuan = null
                  this.rowIC.aranic_risiko = null
                  this.rowIC.aranic_kompilasi = null
                  this.rowIC.aranic_prognosis = null
                  this.rowIC.aranic_alternatif = null
                  this.rowIC.aranic_lainnya = null
                }

            }else{  
                Gen.apiRest('/do/' + this.modulePage, {
                    data: {
                        type: 'get-data-ic',
                        id: v.arankai9d_informed_consent,
                    }
                }, 'POST').then(res => {
                    this.rowIC = res.data.row 
                    this.rowIC.mi9_name = v.mi9_name
                    this.rowIC.mi9_code = v.mi9_code
                    this.rowIC.id_icd = v.arankai9d_id                   
                })
                // console.log(edit)
            }

            this.rowIC.mi9_name = v.mi9_name
            this.rowIC.mi9_code = v.mi9_code
            this.rowIC.id_icd = v.arankai9d_id

            this.openModalIC = true
        },

        openInformConsentDetailForm(v){
            Gen.apiRest('/do/' + this.modulePage, {
                data: {
                    type: 'get-data-ic',
                    id: this.row.arankmb_inform_consent_id,
                }
            }, 'POST').then(res => {
                this.rowIC = res.data.row 
                this.rowIC.mi9_name = this.row.arankmb_tindakan_text
                this.rowIC.mi9_code = ''
                this.rowIC.id_assesment = this.row.arankmb_id
                this.openModalICDetail = true                   
            })
        },
        openInformConsentForm(){
          if(!this.row.arankmb_inform_consent_id){
              this.rowIC.aranic_diagnosis = null
              this.rowIC.aranic_id = null
              this.rowIC.aranic_dasar_diagnosis = null
              this.rowIC.aranic_tindakan_dokter = null
              this.rowIC.aranic_indikasi_tindakan = null
              this.rowIC.aranic_tata_cara = null
              this.rowIC.aranic_tujuan = null
              this.rowIC.aranic_risiko = null
              this.rowIC.aranic_kompilasi = null
              this.rowIC.aranic_prognosis = null
              this.rowIC.aranic_alternatif = null
              this.rowIC.aranic_lainnya = null
              this.rowIC.mi9_name = this.row.arankmb_tindakan_text
              this.rowIC.mi9_code = ''
              this.rowIC.id_assesment = this.row.arankmb_id
          } else{
              Gen.apiRest('/do/' + this.modulePage, {
                  data: {
                      type: 'get-data-ic',
                      id: this.row.arankmb_inform_consent_id,
                  }
              }, 'POST').then(res => {
                  this.rowIC = res.data.row 
                  this.rowIC.mi9_name = this.row.arankmb_tindakan_text                
              })
          }

          this.openModalIC = true
        },

        submitInformConcent(){
            this.$refs['VFormInformconsent'].validate().then(success => {
                if(success){
                    this.openModalIC = false

                    let data = this.rowIC
                    data.type = 'submit-data-inform-consent'
                    data.aranic_aranr_id = this.rowReg.aranr_id
                    data.aranic_name = this.rowIC.mi9_name + (this.rowIC.mi9_code ? " ("+this.rowIC.mi9_code+")" : "")
                    
                    this.loadingOverlay = true
                    Gen.apiRest(
                    "/do/" + this.modulePage, {
                        data: data
                    },
                    "POST"
                    ).then(res => {
                        this.apiGet()  
                        
                        this.rowIC.aranic_id = null

                        return this.$swal({
                            title: "Data Informed Consent berhasil disimpan",
                            icon: 'success',
                            confirmButtonText: 'Ok'
                        })
                    })
                }
            })
        },

        ceilData(value) {
            return Math.ceil(value)
        },

        selectICD9(e) {
            let index = this.mICD9.findIndex(x => x.value == e)
            if (index !== -1) {
                this.inputICD9Name =  this.mICD9[index]['text']
                this.searchICD9Name = null
            }else{
                this.inputICD9Name =  null
            }
            this.addNewICD9()
        },

        selectICD10(e) {
            let index = this.mICD10.findIndex(x => x.value == e)
            if (index !== -1) {
                this.inputICD10Name =  this.mICD10[index]['text']
                this.searchICD10Name = null
            }else{
                this.inputICD10Name =  null
            }
            this.addNewICD10()
        },

        filterICD10: _.debounce(function (e, k) {
            let id = []
            for (let i = 0; i < (this.dataICD10 || []).length; i++) {
                if (this.dataICD10[i]['arankai10d_icd_10']) {
                    id.push(this.dataICD10[i]['arankai10d_icd_10'])
                }
            }
            
            this.searchICD10Name = e

            Gen.apiRest('/do/' + this.modulePage, {
                data: {
                    type: 'select-icd-10',
                    search: e,
                    id: id
                }
                }, 'POST').then(res => {
                this.mICD10 = res.data.data
            })
        }, 10),

        changePageICD10(page, k, act, col) {
            if (act == 'min') {
                if (page > 1) {
                    page -= 1
                    this[col][k]['currentPage'] -= 1
                }
            } else {
                if (page < this.ceilData(this[col][k]['totalRows'] / 5)) {
                    page += 1
                    this[col][k]['currentPage'] += 1
                }
            }
            
            let data = {
                type: 'select-paging-icd-10',
                page: page,
                search: this.searchICD10Name
            }
            Gen.apiRest(
            "/do/" + this.modulePage, {
                data: data
            },
            "POST"
            ).then(res => {
                this.mICD10 = res.data.data
                this.getmICD10(this.dataICD10, k)
            })
        },

        filterICD9: _.debounce(function (e, k) {
            let id = []
            for (let i = 0; i < (this.dataICD9 || []).length; i++) {
                if (this.dataICD9[i]['arankai9d_icd_9']) {
                    id.push(this.dataICD9[i]['arankai9d_icd_9'])
                }
            }

            this.searchICD9Name = e

            Gen.apiRest('/do/' + this.modulePage, {
                data: {
                    type: 'select-icd-9',
                    search: e,
                    id: id
                }
            }, 'POST').then(res => {
                this.mICD9 = res.data.data
            })
        }, 10),

        changePageICD9(page, k, act, col) {
            if (act == 'min') {
                if (page > 1) {
                    page -= 1
                    this[col][k]['currentPage'] -= 1
                }
            } else {
                if (page < this.ceilData(this[col][k]['totalRows'] / 5)) {
                    page += 1
                    this[col][k]['currentPage'] += 1
                }
            }
            let data = {
                type: 'select-paging-icd-9',
                page: page,
                search: this.searchICD9Name
            }
            Gen.apiRest(
            "/do/" + this.modulePage, {
                data: data
            },
            "POST"
            ).then(res => {
                this.mICD9 = res.data.data
                this.getmICD9(this.dataICD9, k)
            })
        },

        addNewICD9() {
            if(this.inputICD9){
            let data = {
                arankai9d_aranrm_id: this.row.aranrm_id,
                arankai9d_icd_9: this.inputICD9,
                type: 'add-icd-9'
            }
            Gen.apiRest(
                "/do/" + this.modulePage, {
                data: data
                },
                "POST"
            ).then(res => {
                let resp = res.data
                this.dataICD9.push(resp.row)
                this.inputICD9 = null
                this.rowIC.aranic_id = null
            })
            }
        },
        removedataICD9(id, k) {
            this.$swal({
                icon: 'warning',
                title: 'Apakah Anda yakin ingin menghapus tindakan ini?',
                showCancelButton: true,
                confirmButtonText: 'Ya',
                cancelButtonText: 'Tidak, kembali'
            }).then(result => {
                if (result.value) {
                  let data = {
                      id: id,
                      type: 'remove-icd-9'
                  }
                  Gen.apiRest(
                  "/do/" + this.modulePage, {
                      data: data
                  },
                  "POST"
                  ).then(() => {
                      this.dataICD9.splice(k, 1)
                  })
                }
            })
        },

        addNewICD10() {
            if(this.inputICD10){
            let data = {
                arankai10d_aranrm_id: this.row.aranrm_id,
                arankai10d_icd_10: this.inputICD10,
                type: 'add-icd-10'
            }
            Gen.apiRest(
                "/do/" + this.modulePage, {
                data: data
                },
                "POST"
            ).then(res => {
                let resp = res.data
                this.dataICD10.push(resp.row)
                this.inputICD10 = null
            })
            }
        },
        removedataICD10(id, k) {
            let data = {
            id: id,
            type: 'remove-icd-10'
            }
            Gen.apiRest(
            "/do/" + this.modulePage, {
                data: data
            },
            "POST"
            ).then(() => {
            this.dataICD10.splice(k, 1)
            })
        },

        getmICD9(diagnosa, k) {
            const sldMenu = _.map(_.filter(diagnosa || [], (v2, k2) => {
            return k2 != k
            }), v2 => {
            return v2.api9_icd_9
            })
            return _.filter(this.mICD9, v2 => {
            return sldMenu.indexOf(v2.value) <= -1
            })
        },

        getmICD10(diagnosa, k) {
            const sldMenu = _.map(_.filter(diagnosa || [], (v2, k2) => {
            return k2 != k
            }), v2 => {
            return v2.api10_icd_10
            })
            return _.filter(this.mICD10, v2 => {
            return sldMenu.indexOf(v2.value) <= -1
            })
        },


        
        searchICD10Data: _.debounce(function () {
            let data = {
                search: this.searchICD10,
                aranr_ap_id: this.rowReg.aranr_ap_id,
                type: 'search-icd-10'
            }
            Gen.apiRest(
            "/do/" + this.modulePage, {
                data: data
            },
            "POST"
            ).then(res => {
                let resp = res.data
                this.riwayatICD10 = resp.data
            })
        }, 500),

        searchICD9Data: _.debounce(function () {
            let data = {
                search: this.searchICD9,
                aranr_ap_id: this.rowReg.aranr_ap_id,
                type: 'search-icd-9'
            }
            Gen.apiRest(
            "/do/" + this.modulePage, {
                data: data
            },
            "POST"
            ).then(res => {
                let resp = res.data
                this.riwayatICD9 = resp.data
            })
        }, 500),


        saveICD10() {
            if (this.selectedICD10) {
            let data = {
                id: this.selectedICD10,
                aranrm_id: this.row.aranrm_id,
                type: 'save-riwayat-icd-10'
            }
            Gen.apiRest(
                "/do/" + this.modulePage, {
                data: data
                },
                "POST"
            ).then(() => {
                this.openriwayatICD10 = false
                this.resetData()
            })
            }
        },

        saveICD9() {
            if (this.selectedICD9) {
            let data = {
                id: this.selectedICD9,
                aranrm_id: this.row.aranrm_id,
                type: 'save-riwayat-icd-9'
            }
            Gen.apiRest(
                "/do/" + this.modulePage, {
                data: data
                },
                "POST"
            ).then(() => {
                this.openriwayatICD9 = false
                this.resetData()
            })
            }
        },

        
        resetData() {
            this.searchICD10 = '',
            this.searchICD9 = '',
            this.apiGet()
        },

        // Assesmen Start

        doSubmit(){
            
            if(this.row.aranrm_is_active == 'N'){
                return this.$swal({
                    icon: 'error',
                    title: 'Data Telah tidak Aktif',
                    text: 'Silakan Kembali Ke Dashboard dan cari no Registrasi yang sama'
                }).then(result => {
                    if (result.value) {
                        setTimeout(()=>{
                            let inv = []
                            let el = document.querySelectorAll('.label_error')
                            for(let i = 0; i < (el||[]).length; i++){
                                if(el[i].style.display !== 'none'){
                                    inv.push(el[i].id)
                                }
                            }
                            if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                        },500)
                    }
                })
            }   


            this.$refs['VFormDokter'].validate().then(success => {
                console.log(success)
                if (!success) {
                    return this.$swal({
                        icon: 'error',
                        title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                            setTimeout(() => {
                                let inv = []
                                let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                                console.log(el)
                                for (let i = 0; i < (el || []).length; i++) {
                                    if (el[i].style.display !== 'none') {
                                    inv.push(el[i].id)
                                    }
                                }

                                let PA = ['Tipe Bentuk',
                                'Bentuk',
                                'Tipe Suturae',
                                'Suturae',
                                'Fontanel',
                                'Caput',
                                'Septal',
                                'Tipe Mata',
                                'Mata',
                                'Tipe Telinga',
                                'Telinga',
                                'Palatum']
                                                                                
                                for(let i = 0; i < (inv||[]).length; i++){
                                    if(PA.indexOf(inv[i]) != -1){
                                        return this.$swal({
                                            icon: 'error',
                                            title: `Kepala Belum Diisi`
                                        })
                                    }
                                }
                                            
                                if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'center'
                                })
                            }, 500)
                            
                        }
                    })
                }
                if (success) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Apakah Anda Yakin akan menyimpan data ini?',
                        showCancelButton: true,
                        confirmButtonText: 'Ya',
                        cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            let data = this.row
                            data.type = 'submit-data'
                            data.dataICD9 = this.dataICD9
                            data.dataICD10 = this.dataICD10

                            this.loadingOverlay = true
                            Gen.apiRest(
                            "/do/" + this.modulePage, {
                                data: data
                            },
                            "POST"
                            ).then(res => {
                                this.loadingOverlay = false
                                
                                let resp = res.data
                                resp.statusType = 200
                                this.$swal({
                                    title: resp.message,
                                    icon: resp.status,
                                    confirmButtonText: 'Ok',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false
                                }).then(result => {
                                    this.$router.push({ name: 'RanapPasienPerawatanDPJP'}).catch(()=>{})   
                                })
                            })
                        }
                    })
                }
            })
        }
    },
    mounted() {
        this.apiGet() 

        this.sockets.subscribe('collaborate_kajian-awal-bbl', data => {
            if(data.regId != this.$route.query.regId) {
                return
            }
            
            if(data.type == 'set-disable'){
                this.$set(this.disabled, data.field, true)
            }
            else if(data.type == 'set-enable'){
                this.$set(this.disabled, data.field, false)
            }
            else if(data.type == 'set-value'){
                if(data.field == 'anak-ke'){
                    this.$set(this.row, 'arankmb_anak_ke', data.value)
                }
                if(data.field == 'jenis-kelamin'){
                    this.$set(this.row, 'arankmb_jenis_kelamin', data.value)
                }
                if(data.field == 'berat-badan'){
                    this.$set(this.row, 'arankmb_berat_badan', data.value)
                }
                if(data.field == 'panjang-badan'){
                    this.$set(this.row, 'arankmb_panjang', data.value)
                }
                if(data.field == 'lingkar-kepala'){
                    this.$set(this.row, 'arankmb_lingkar_kepala', data.value)
                }
                if(data.field == 'lingkar-dada'){
                    this.$set(this.row, 'arankmb_lingkar_dada', data.value)
                }
                if(data.field == 'nadi'){
                    this.$set(this.row, 'arankmb_nadi', data.value)
                }
                if(data.field == 'rr'){
                    this.$set(this.row, 'arankmb_rr', data.value)
                }
                if(data.field == 'suhu-badan'){
                    this.$set(this.row, 'arankmb_suhu', data.value)
                }
            }

            console.log(this.disabled)
            console.log(data)
        })

        this.getPatientInfo()  
    },
    
    watch:{
        row: {
            handler(v) {
                this.autoSave(v)
            },
            deep: true
        }
    },

    destroyed(){
        this.sockets.unsubscribe('collaborate_kajian-awal-bbl')
    }
}
</script>